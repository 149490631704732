import { sortAscending } from "../../../utility/utility"

const initialState = {
  allStoriesData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotStoryListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_STORIES_DATA":
      return {
        ...state,
        allStoriesData: sortAscending(action.data, "name")
      }
    case "ADD_STORY_DATA": {
      const { payload } = action

      return {
        ...state,
        allStoriesData: sortAscending(state.allStoriesData.concat(payload), "name")
      }
    }
    case "UPDATE_STORY_DATA": {
      const { payload } = action

      return {
        ...state,
        allStoriesData: state.allStoriesData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_STORY_DATA": {
      const { payload } = action

      return {
        ...state,
        allStoriesData: state.allStoriesData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotStoryListReducer
