/**
 * THE REDUCER for partner
 * @author Sandeep G
 * @sice 20201223
 */
const initialState = {
  modalDeleteDataPartner: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  params: null,
  allData: [],
};
const PartnerDataListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "SET_PARTNER_DATA"://##
      return {
        ...state,
        allData: action.data
      }
    case "OPEN_MODEL_DELETE_PARTNER":
      return {
        ...state,
        modalDeleteDataPartner: action.value,
      }
    case "FILTER_PARTNER_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.reference.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.reference.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
        // .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }

    case "DELETE_PARTNER_DATA":
      return {
        ...state,
        allData: state.allData.filter((item) => item.id !== payload)
      }
    case "ADD_PARTNER_DATA":
      return {
        ...state,
        allData: state.allData.concat(payload)
      }
    case "UPDATE_PARTNER_DATA":
      return {
        ...state,
        allData: state.allData.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }

    case "UPDATE_PARTNER_LOGO_PATH_LOCALLY":
      const { id, url } = payload
      return {
        ...state,
        allData: state.allData.map((user) => {
          return user.id === id ? { ...user, partnerIconPath: url } : user
        }),
      }

    default:
      return state
  }
}

export default PartnerDataListReducer
