import { sortAscending } from "../../../utility/utility"

const initialState = {
  allEntitiesData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotEntityListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_ENTITIES_DATA":
      return {
        ...state,
        allEntitiesData: sortAscending(action.data, "entityId")
      }
    case "ADD_ENTITY_DATA": {
      const { payload } = action

      return {
        ...state,
        allEntitiesData: sortAscending(state.allEntitiesData.concat(payload), "entityId")
      }
    }
    case "UPDATE_ENTITY_DATA": {
      const { payload } = action

      return {
        ...state,
        allEntitiesData: state.allEntitiesData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_ENTITY_DATA": {
      const { payload } = action

      return {
        ...state,
        allEntitiesData: state.allEntitiesData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotEntityListReducer
