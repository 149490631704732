import { sortAscending } from "../../../utility/utility"

const initialState = {
  allIntentsData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotIntentListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_INTENTS_DATA":
      return {
        ...state,
        allIntentsData: sortAscending(action.data, "name")
      }
    case "ADD_INTENT_DATA": {
      const { payload } = action

      return {
        ...state,
        allIntentsData: sortAscending(state.allIntentsData.concat(payload), "name")
      }
    }
    case "UPDATE_INTENT_DATA": {
      const { payload } = action

      return {
        ...state,
        allIntentsData: state.allIntentsData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_INTENT_DATA": {
      const { payload } = action

      return {
        ...state,
        allIntentsData: state.allIntentsData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotIntentListReducer
