import { sortAscending } from "../../../utility/utility"

const initialState = {
  allSlotsData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotSlotListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_SLOTS_DATA":
      return {
        ...state,
        allSlotsData: sortAscending(action.data, "name")
      }
    case "ADD_SLOT_DATA": {
      const { payload } = action

      return {
        ...state,
        allSlotsData: sortAscending(state.allSlotsData.concat(payload), "name")
      }
    }
    case "UPDATE_SLOT_DATA": {
      const { payload } = action

      return {
        ...state,
        allSlotsData: state.allSlotsData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_SLOT_DATA": {
      const { payload } = action

      return {
        ...state,
        allSlotsData: state.allSlotsData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotSlotListReducer
