import React from 'react'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Media
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Status = () => {
  return (
    <UncontrolledDropdown
      className="dropdown-notification nav-item"
    >
      <DropdownToggle tag="a" className="nav-link nav-link-label">
        Status
        <FontAwesomeIcon icon={faCircle} className="col-green" />

      </DropdownToggle>
      <DropdownMenu tag="ul" right className="dropdown-menu-media" id="status-dropdown">
        <li className="dropdown-menu-header">
          <div className="dropdown-header mt-0">
            <h3 className="text-white">Status</h3>
          </div>
        </li>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false
          }}
        >
          <div className="d-flex justify-content-between">
            <Media className="d-flex align-items-start">
              <Media body>
                <p className="notification-text">
                  Operational: No issues reported
                </p>
              </Media>
            </Media>
          </div>
        </PerfectScrollbar>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Status;
