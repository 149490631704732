const initialState = {
  // Entity Type
  entityTypeTypes: [
    { id: "Business", value: "Business" },
    { id: "Technical", value: "Technical" }
  ],
  attributeTypeTypes: [
    { id: "String", value: "String" },
    { id: "Number", value: "Number" },
    { id: "DateTime", value: "Date Time" },
    { id: "Miscellaneous", value: "Miscellaneous" }
  ],

  allEntityTypes: [],

  tableLoadingForET: false,
  sidebarLoadingForET: false,

  // Entity Type Instance
  allEntityTypeInstances: [],

  tableLoadingForETI: false,
  sidebarLoadingForETI: false
};

const KnowledgeBaseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_TABLE_LOADING_FOR_ENTITY_TYPE":
      return {
        ...state,
        tableLoadingForET: payload
      }

    case "SET_SIDEBAR_LOADING_FOR_ENTITY_TYPE":
      return {
        ...state,
        sidebarLoadingForET: payload
      }

    case "GET_ALL_ENTITY_TYPES_DATA":
      return {
        ...state,
        allEntityTypes: payload
      };

    case "ADD_ENTITY_TYPE_DATA":
      return {
        ...state,
        allEntityTypes: state.allEntityTypes.concat(payload)
      }

    case "DELETE_ENTITY_TYPE_DATA":
      return {
        ...state,
        allEntityTypes: state.allEntityTypes.filter((item) => item.id !== payload)
      }

    case "SET_ENTITY_INSTANCE_ATTRIBUTE_TYPES": {
      const { id, attributes } = payload
      return {
        ...state,
        allEntityTypes: state.allEntityTypes.map((item) => {
          if (item.id === id) {
            return { ...item, attributes }
          }
          return item
        })
      }
    }

    case "ADD_ATTRIBUTE_TYPES_DATA": {
      const { id, attributes } = payload
      return {
        ...state,
        allEntityTypes: state.allEntityTypes.map((item) => {
          if (item.id === id) {
            return { ...item, attributes: (item.attributes || []).concat(attributes) }
          }
          return item
        })
      }
    }

    case "UPDATE_ATTRIBUTE_TYPES_DATA": {
      const { id, attributes } = payload
      return {
        ...state,
        allEntityTypes: state.allEntityTypes.map((item) => {
          if (item.id === id) {
            return { ...item, attributes }
          }
          return item
        })
      }
    }

    case "GET_ALL_ENTITY_TYPE_INSTANCES":
      return {
        ...state,
        allEntityTypeInstances: payload
      };

    case "ADD_ENTITY_TYPE_INSTANCE":
      return {
        ...state,
        allEntityTypeInstances: state.allEntityTypeInstances.concat(payload)
      }

    case "UPDATE_ATTRIBUTE_TYPE_INSTANCE": {
      const { id, attributes } = payload
      return {
        ...state,
        allEntityTypeInstances: state.allEntityTypeInstances.map((item) => {
          if (item.id === id) {
            return { ...item, attributes }
          }
          return item
        })
      }
    }
    case "DELETE_ENTITY_TYPE_INSTANCE":
      return {
        ...state,
        allEntityTypeInstances: state.allEntityTypeInstances.filter((instance) => instance.id !== payload)
      }

    case "SET_TABLE_LOADING_FOR_ENTITY_TYPE_INSTANCE":
      return {
        ...state,
        tableLoadingForETI: payload
      }

    case "SET_SIDEBAR_LOADING_FOR_ENTITY_TYPE_INSTANCE":
      return {
        ...state,
        sidebarLoadingForETI: payload
      }
    default:
      return state;
  }
};

export default KnowledgeBaseReducer;
