const initialState = {
  sidebarLoading: false
}

const ChatbotVersionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_CHATBOT_VERSION_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotVersionReducer
