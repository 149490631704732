import { sortAscending } from "../../../utility/utility"

const initialState = {
  portStatusOptions: [
    { id: "Available", value: "Available" },
    { id: "Assigned", value: "Assigned" }
  ],
  allServersData: [],
  serverDataById: {},

  tableLoading: false,
  detailsLoading: false
}

const BotServersReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_BOT_SERVERS_DATA":
      return {
        ...state,
        allServersData: sortAscending(payload, "name")
      }
    case "GET_BOT_SERVER_DATA_BY_ID": {
      const { payload } = action

      return {
        ...state,
        serverDataById: payload
      }
    }
    case "ADD_BOT_SERVER_DATA": {
      return {
        ...state,
        allServersData: sortAscending(state.allServersData.concat(payload), "name")
      }
    }
    case "UPDATE_BOT_SERVER_DATA": {
      return {
        ...state,
        allServersData: state.allServersData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_BOT_SERVER_DATA": {
      return {
        ...state,
        allServersData: state.allServersData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_DETAILS_LOADING":
      return {
        ...state,
        detailsLoading: payload
      }

    default:
      return state
  }
}

export default BotServersReducer
