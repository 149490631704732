const initialState = {
  allExtractionModels: [],
  allProfiles: [],

  tableLoading: false,
  sidebarLoading: false
};

const WaivesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_WAIVE_DATA":
      return {
        ...state,
        allWaivesData: state.allWaivesData.concat(payload),
      };
    case "UPDATE_WAIVE_DATA":
      return {
        ...state,
        allWaivesData: state.allWaivesData.map((item) =>
          item.id === payload.id ? { ...payload } : item
        ),
      };
    case "DELETE_WAIVE_DATA":
      return {
        ...state,
        allWaivesData: state.allWaivesData.filter(
          (item) => item.id !== payload
        ),
      };

    case "GET_ALL_EXTRACTION_MODELS":
      return {
        ...state,
        allExtractionModels: payload
      }
    case "GET_ALL_PROFILES":
      return {
        ...state,
        allProfiles: payload
      }
    case "ADD_PROFILE":
      return {
        ...state,
        allProfiles: state.allProfiles.concat(payload)
      }
    case "UPDATE_PROFILE": {
      const profileProfileId = payload.id
      return {
        ...state,
        allProfiles: state.allProfiles.map((profile) => {
          if (profile.id === profileProfileId) {
            return payload
          }
          return profile
        })
      }
    }
    case "DELETE_PROFILE": {
      return {
        ...state,
        allProfiles: state.allProfiles.filter((profile) => profile.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state;
  }
};

export default WaivesReducer;
