import { sortAscending } from "../../../utility/utility"

const initialState = {
  allFormsData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotFormListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_FORMS_DATA":
      return {
        ...state,
        allFormsData: sortAscending(action.data, "name")
      }
    case "ADD_FORM_DATA": {
      const { payload } = action

      return {
        ...state,
        allFormsData: sortAscending(state.allFormsData.concat(payload), "name")
      }
    }
    case "UPDATE_FORM_DATA": {
      const { payload } = action

      return {
        ...state,
        allFormsData: state.allFormsData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_FORM_DATA": {
      const { payload } = action

      return {
        ...state,
        allFormsData: state.allFormsData.filter((item) => item.id !== payload)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotFormListReducer
