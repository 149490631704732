import Axios from "axios";
import cloneDeep from 'lodash/cloneDeep';

import alertCall from "../../components/@self/CustomAlert";

import { getUrl } from "./Config";
import { getDecryptedItem, setEncryptedItem } from "../encryptedStorage";
import { store } from "../../redux/storeConfig/store"

const CONFIG = getUrl();
const CONFIG_URL = CONFIG.URL
const CORS_WORKAROUND = ""; //'https://cors-anywhere.herokuapp.com/';

const LOGS_BASEURL = CONFIG_URL.LOGS.GET_LOGS_URL;
const AUTH0_BASE_URL = CONFIG_URL.AUTH0.BASE_URL
const CHATBOT_BASE_URL = CONFIG_URL.CHATBOT.BASE_URL
const KNOWLEDGE_BASE_URL = CONFIG_URL.KNOWLEDGE_BASE.BASE_URL

///////////////////////////////////
const getHeaderData = (customOptionsObj = {}) => {
  const customOptions = customOptionsObj || {}

  if (getDecryptedItem("orgName")) {
    const userData = JSON.parse(getDecryptedItem("data"));
    const userInfo = userData?.userInfo
    const headers = {}

    if (userInfo) {
      const { username, customerId, name, id: userId } = userInfo
      if (username) {
        headers.user_email = username || ""
      }
      if (userId) {
        headers.user_id = userId || ""
      }

      if (customerId) {
        headers.user_org = customerId
        headers.organisationId = customerId
      } else if (getDecryptedItem("loggedInUserRole") === "Customer") {
        headers.user_org = userId || ""
        headers.organisationId = userId || ""
      } else if (getDecryptedItem("loggedInUserRole") === "Partner") {
        headers.user_org = ""
        headers.organisationId = ""
      } else if (getDecryptedItem("loggedInUserRole") === "Super Admin") {
        headers.organisationId = ""
        headers.user_org = ""
      } else {
        headers.organisationId = ""
        headers.user_org = ""
      }

      headers.username = name || ""
    }

    const token = getDecryptedItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return {
      ...customOptions,
      headers: {
        ...headers,
        ...cloneDeep(customOptions.headers || {})
      }
    }
  }

  return {
    ...customOptions,
    headers: {
      ...cloneDeep(customOptions.headers || {})
    }
  };
}


function get(url, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.get(url, head_data).then((data) => {
      resolve(data);
    }, (e) => {
      reject(e);
    });
  });
}

function post(url, param, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.post(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });

}

function put(url, param) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData();

  return new Promise((resolve, reject) => {
    Axios.put(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });
}

function deleteApi(url, param, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.delete(url, { ...head_data, data: param }).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });
}
///////////////////////////////////

function getCollections(orgId) {
  let url = CORS_WORKAROUND + CONFIG_URL.COLLECTIONS.GET;
  const userData = JSON.parse(getDecryptedItem("data"));
  const userInfo = userData?.userInfo
  let header_data_lock = {
    username: userInfo.username,
    user_org: orgId || userInfo.customerId,
  };

  return new Promise((resolve, reject) => {
    get(url, { headers: { header_data_lock } }).then(
      (response) => {
        resolve(response.data);
      },
      (e) => {
        reject(e);
      }
    );
  });
}

function getFilters(id) {
  let url = CORS_WORKAROUND + CONFIG_URL.FILTERS.GET;
  url = url.replace('$id', id);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });

}

function getDocuments(id, param) {
  param = param || '';
  let url = CORS_WORKAROUND + CONFIG_URL.DOCUMENTS.GET + param;
  url = url.replace('$id', id);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getEntities(cid, did) {
  let url = CORS_WORKAROUND + CONFIG_URL.ENTITIES.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });

}

function getDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG_URL.DOCUMENT.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getMappings(cid, did) {
  let url = CORS_WORKAROUND + CONFIG_URL.MAPPINGS.GET;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getGroups() {
  let url = CORS_WORKAROUND + CONFIG_URL.GROUPS.GET;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function uploadDoc(cid, groupName, fileData) {
  let url = CORS_WORKAROUND + CONFIG_URL.DOCUMENT.POST
  if (groupName) {
    url += '?group=' + groupName;
  }
  url = url.replace('$cid', cid);

  return new Promise((res, rej) => {
    post(url, fileData).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function updateEntities(cid, did, param) {
  let url = CORS_WORKAROUND + CONFIG_URL.ENTITIES.PUT;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    put(url, param).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function syncGoogleDrive(cid, did, workspaceName, workspaceId, organisationId) {
  let url = CONFIG_URL.SYNCGOOGLEDRIVE.POST;
  let param = {
    collectionId: cid,
    documentId: did,
    workspaceName: workspaceName,
    workspaceId: workspaceId,
    organisationId: organisationId,
  };
  return new Promise((res, rej) => {
    post(url, param).then(
      (resp) => {
        let data = resp.data;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

function updateMappings(cid, did, type, param) {
  let url = CORS_WORKAROUND + CONFIG_URL.MAPPINGS.POST;
  url = url.replace('$cid', cid).replace('$did', did);
  url += `?mode=${type || 'save'}`

  return new Promise((res, rej) => {
    post(url, param).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function deleteDocument(cid, did) {
  let url = CORS_WORKAROUND + CONFIG_URL.DOCUMENT.DELETE;
  url = url.replace('$cid', cid).replace('$did', did);
  return new Promise((res, rej) => {
    deleteApi(url).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

/**
 * All APIs related to the partner goes here
 */
const savePartner = (data) => {
  let url = `${AUTH0_BASE_URL}/api/partner/add`;
  return new Promise((res, rej) => {
    post(url, data).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });
}

const getPartners = () => {
  let url = `${AUTH0_BASE_URL}/api/partner/listAll`;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });
}

const deletePartner = (data) => {
  let id = data.id;
  let url = `${AUTH0_BASE_URL}/api/partner/delete/${id}`;
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);

    }, (e) => {
      rej(e);
    });
  });
}

/**
 * All APIs related to the user Login goes here
 */

const userLogin = (data) => {
  let url = `${AUTH0_BASE_URL}/api/user/login`;
  return new Promise((res, rej) => {
    post(url, data).then((resp) => {
      res(resp.data);
    }, (e) => {
      rej(e);
    });
  });
}

const getOrgDetails = (id, role) => {
  var url;
  if (role === 'CustomerAdminSetup') {
    url = `${CONFIG_URL.AUTH0.BASE_URL}/api/partner/${id}`;
  } else {
    url = `${CONFIG_URL.AUTH0.BASE_URL}/api/customer/${id}`;
  }
  return new Promise((res, rej) => {
    get(url).then((resp) => {
      let data = resp.data;
      setEncryptedItem('orgName', data.name);
      res(data);
    }, (e) => {
      rej(e);
    });
  });
}
/**
 * APIs For user Image.....
 */

const uploadUserImg = (file, isProfile, organisationId, userId) => {
  var formData = new FormData();
  formData.append('image ', file, file.name);

  let url = AUTH0_BASE_URL + '/api/file/server/upload';
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return new Promise((resolve, reject) => {
    post(url, formData, { params: { isProfile, organisationId, userId } }, config).then((response) => {
      console.log("response", response)
      const { status, jsonData } = response?.data || {}
      if (status && jsonData) {
        resolve(`${AUTH0_BASE_URL}/api/file/server/download/${jsonData}`)
      }
      resolve(false)
    }, (e) => {
      reject(e);
    });
  });
}

const removeUserImg = (imageURL) => {
  const imageId = imageURL.replace(`${AUTH0_BASE_URL}/api/photo/`, '').replace(`${AUTH0_BASE_URL}/api/file/server/download/`, '')

  return new Promise((resolve, reject) => {
    let url = AUTH0_BASE_URL + `/api/file/server/delete/${imageId}`;
    deleteApi(url).then((response) => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

const changePasswordApi = (email) => {
  return new Promise((resolve, reject) => {
    get(`${AUTH0_BASE_URL}/api/user/changePassword/${email}`).then(response => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  })
}

function getLogs(params, userOrgId) {
  params = params ? '&' + params : '';
  let url = LOGS_BASEURL + "/logs?size=200" + params;
  return new Promise((res, rej) => {
    get(url, { headers: { user_org: userOrgId } }).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      rej(e);
    });
  });
}

function getLogFilters(userOrgId) {
  let url = LOGS_BASEURL + "/filters";
  return new Promise((res, rej) => {
    get(url, { headers: { user_org: userOrgId } }).then((resp) => {
      let data = resp.data;
      res(data);

    }, (e) => {
      //change when API fixed
      // const sampleData = {
      //     username: ["abc.com", "xyz.com"],
      //     resource: ["WorkItem"],
      //     action: ["Delete", "Import", "Classification"],
      //     type: ["Delete", "Create", "Info"],
      //     severity: ["danger", "warning"]
      // }
      rej(e);
    });
  });
}

const getUserInfoFromAuth0Token = (token) => {
  const url = `${CONFIG_URL.AUTH0.USER_INFO}`;

  return new Promise((res, rej) => {
    get(url, { headers: { token } }).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
};

const sendUserMessageToRASA = ({ url, chatbotId, message, environment }) => {
  let userData = getDecryptedItem("data")

  try {
    userData = userData && JSON.parse(userData)
    const { username, customerId, id: userId } = userData?.userInfo || {}

    return new Promise((res, rej) => {
      post(url, {
        chatbotId,
        env: environment,
        request: {
          message,
          sender: userId,
          organisationId: customerId,
          email: username
        }
      }).then(
        (resp) => {
          const { data } = resp;
          res(data);
        },
        (e) => {
          rej(e);
        }
      );
    });
  } catch (err) {
    console.log("catch err", err)
    return new Promise((res, rej) => {
      rej(err)
    })
  }
}

const getActionDetails = (actionId) => {
  const url = `${CHATBOT_BASE_URL}/action/get/${actionId}`;

  return new Promise((res, rej) => {
    get(url).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

const getSlotDetails = (slotId) => {
  const url = `${CHATBOT_BASE_URL}/slot/get/${slotId}`;

  return new Promise((res, rej) => {
    get(url).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

const exportEntityTypeTemplate = async (entityTypeObject) => {
  const { id, name } = entityTypeObject
  const url = `${KNOWLEDGE_BASE_URL}/api/entities/${id}/template`
  const config = {
    'responseType': 'blob'
  };

  return new Promise((res, rej) => {
    get(url, config).then(
      (response) => {
        console.log("exportEntityTypeTemplate response", response)
        if (response.status === 200) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] })
          link.setAttribute('download', `${name}-template.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          res(true)
        } else {
          alertCall("", "danger")
          res(false)
        }
      },
      (e) => {
        console.log("exportEntityTypeTemplate catch e", e)
        alertCall(e?.response?.data?.message, "danger")
        rej(e);
      }
    );
  });
}

const exportEntityTypeInstanceData = async (entityTypeObject) => {
  const { id, name } = entityTypeObject
  const url = `${KNOWLEDGE_BASE_URL}/api/entities/${id}/data`
  const config = {
    'responseType': 'blob'
  };

  return new Promise((res, rej) => {
    get(url, config).then(
      (response) => {
        console.log("exportEntityTypeInstanceData response", response)
        if (response.status === 200) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] })
          link.setAttribute('download', `${name}-data.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          res(true)
        } else {
          alertCall("", "danger")
          res(false)
        }
      },
      (e) => {
        console.log("exportEntityTypeInstanceData catch e", e)
        alertCall(e?.response?.data?.message, "danger")
        rej(e);
      }
    );
  });
}

const importEntityTypeInstanceData = async (entityTypeObject, excelFile) => {
  const { id } = entityTypeObject
  const url = `${KNOWLEDGE_BASE_URL}/api/entities/${id}/data`
  const formData = new FormData();
  formData.append('file', excelFile, excelFile.name);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return new Promise((resolve, reject) => {
    post(url, formData, config).then((response) => {
      console.log("importEntityTypeInstanceData response", response)
      if (response.status === 200) {
        alertCall("Data imported successfully!", "success")
        resolve(true)
      } else {
        alertCall("", "danger")
        resolve(false)
      }
    }, (e) => {
      console.log("importEntityTypeInstanceData catch e", e)
      reject(e);
      alertCall(e?.response?.data?.message, "danger")
    });
  });
}

const getUserById = (userId) => {
  const url = `${AUTH0_BASE_URL}/api/user/${userId}`;

  return new Promise((res, rej) => {
    get(url).then((resp) => {
      res(resp.data);
    }, (e) => {
      rej(e);
    });
  });
}

const uploadChatbotFile = (file, chatbotId) => {
  let url = `${CHATBOT_BASE_URL}/v1/files/upload`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('chatbotId', chatbotId);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return new Promise((resolve, reject) => {
    post(url, formData, config).then((response) => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

const downloadChatbotFile = (fileObject) => {
  const { name: fileName, id: fileId } = fileObject;
  let url = `${CHATBOT_BASE_URL}/v1/files/download/${fileId}`;
  const config = {
    'responseType': 'blob'
  };

  return new Promise((res, rej) => {
    get(url, config).then(
      (response) => {
        console.log("exportEntityTypeInstanceData response", response)
        if (response.status === 200) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] })
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          res(true)
        } else {
          alertCall("", "danger")
          res(false)
        }
      },
      (e) => {
        console.log("exportEntityTypeInstanceData catch e", e)
        alertCall(e?.response?.data?.message, "danger")
        rej(e);
      }
    );
  });
}

const uploadMongoDbFile = (file) => {
  let url = `${CHATBOT_BASE_URL}/v1/files/mongodb/upload`;

  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return new Promise((resolve, reject) => {
    post(url, formData, config).then((response) => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

const downloadMongoDbFile = (fileId) => {
  let url = `${CHATBOT_BASE_URL}/v1/files/mongodb/download/${fileId}`;

  return new Promise((resolve, reject) => {
    get(url).then((response) => {
      console.log("downloadMongoDbFile response", response);
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

const deleteFile = (fileId) => {
  let url = `${CHATBOT_BASE_URL}/v1/files/delete/${fileId}`;

  return new Promise((resolve, reject) => {
    deleteApi(url).then((response) => {
      resolve(response.data);
    }, (e) => {
      reject(e);
    });
  });
}

function logOut() {
  store.dispatch({ type: "USER_LOGOUT" })
  sessionStorage.clear();
  localStorage.clear();

  window.location.href = "/login"
}

const forceLogOut = () => {
  logOut()
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

export {
  get,
  post,
  put,
  deleteApi,
  getCollections,
  getFilters,
  getDocuments,
  getDocument,
  getEntities,
  getMappings,
  uploadDoc,
  updateEntities,
  updateMappings,
  deleteDocument,
  getGroups,
  savePartner,
  getPartners,
  syncGoogleDrive,
  userLogin,
  deletePartner,
  uploadUserImg,
  removeUserImg,
  getOrgDetails,
  changePasswordApi,
  getLogs,
  getLogFilters,
  getUserInfoFromAuth0Token,
  sendUserMessageToRASA,
  getActionDetails,
  getSlotDetails,
  exportEntityTypeTemplate,
  exportEntityTypeInstanceData,
  importEntityTypeInstanceData,
  getUserById,
  uploadChatbotFile,
  downloadChatbotFile,
  uploadMongoDbFile,
  downloadMongoDbFile,
  deleteFile,
  logOut,
  forceLogOut
};
