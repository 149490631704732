import img1 from "../assets/img/users/user1-darren-collins.jpg"
import img2 from "../assets/img/users/user2-Derek-1.jpg"
import img3 from "../assets/img/users/user3-Derek-2.jpg"
import img4 from "../assets/img/users/user4-michael morgan.jpg"
import img5 from "../assets/img/users/user5-neil-briody.jpg"
import img6 from "../assets/img/users/user6-simon-brookes.jpg"

const credentials = [
    {//Warning! never change the first element, this is default values
        name: '',
        email: '',
        status: ['user'],
        company: 'Cloudhub360',
        image: 'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png'
    },
    {
        name: 'Gaurav',
        email: 'gaurav@dexninja.com',
        status: ['admin'],
        company: 'Dexninja',
        image: 'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png'
    },
    {
        name: 'Sandeep',
        email: 'uplinksandy9@gmail.com',
        status: ['developer'],
        company: 'Google',
        image: img6
    },
    {
        name: 'Vinay',
        email: 'vinay.mishra@cerridd.com',
        status: ['admin'],
        company: 'Cerrid Solutions',
        image: 'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png'
    },
    {
        name: 'Darren Collins',
        email: 'darren.collins@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img1
    },
    {
        name: 'Derek Kilroe',
        email: 'derek.kilroe@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img3
    },
    {
        name: 'Michael Morgan',
        email: 'michael.morgan@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img4
    },
    {
        name: 'Simon Brookes',
        email: 'simon.brookes@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img6
    },
    {
        name: 'Derek Owen',
        email: 'derek.owen@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img2
    },
    {
        name: 'Neil Briody',
        email: 'neil.briody@cloudhub3601.com',
        status: ['admin'],
        company: 'Cloudhub360',
        image: img5
    }
]

export default credentials;
