import { getDecryptedItem } from "../../../utility/encryptedStorage";

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

export const openLoginFailed = (value) => {
  return (dispatch) => dispatch({ type: "OPEN_MODEL_LOGIN_FAILED", value });
};

export const openAppNotAllowed = (value) => {
  return (dispatch) => dispatch({ type: "OPEN_MODEL_APP_NOT_ALLOWED", value });
};

export const getLoginData = (dataObject) => {
  let loginData
  let loggedInUserRole
  let partnerId
  let customerId
  if (!dataObject) {
    loginData = JSON.parse(getDecryptedItem("data"));
    loggedInUserRole = getDecryptedItem("loggedInUserRole")
    partnerId = getDecryptedItem("partnerId")
    customerId = getDecryptedItem("customerId")
  } else {
    loginData = dataObject.loginData
    loggedInUserRole = dataObject.loggedInUserRole
    partnerId = dataObject.partnerId
    customerId = dataObject.customerId
  }

  return (dispatch) =>
    dispatch({
      type: "GET_LOGIN_DATA",
      loggedInData: loginData,
      loggedInUserRole: loggedInUserRole,
      partnerId: partnerId,
      customerId: customerId,
    });
};
