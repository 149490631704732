function getServerType() {
  const { hostname } = window.location
  switch (hostname) {
    case "test.cloudhub360.com":
      return "test"
    case "demo.cloudhub360.com":
      return "demo"
    default:
      return "test"
  }
}

function getRoutes() {
  return {
    ROUTE: {
      COLLECTIONS: 'collections',
      DETAILS: 'details'
    }
  }
}

function getUrl() {
  // const BASE = 'http://ec2-35-176-120-96.eu-west-2.compute.amazonaws.com:5000';
  try {
    let BASE_APPLICATION = '';
    let BASE_URL_IMPORT_EXPORT = '';
    let WORKBENCH_URL = '';
    let BASE_URL_LOGS = "";
    let AUTH0_BASE_URL = "";
    let CHATBOT_BASE_URL = "";
    let KNOWLEDGE_BASE_URL = "";
    let BILLING_MANAGER_URL = '';
    let ASKELIE_TEST_REDIRECTION_URL = '';

    /* BASE URL SEGREGATION BASED ON ENVIRONMENT STARTS HERE */
    const server = getServerType()

    // Test Server 
    if (server === "test") {
      WORKBENCH_URL = 'https://test.workbench.cloudhub360.com';                     //For UI Workbench
      BASE_APPLICATION = 'https://test.server.cloudhub360.com'                              //For Workbench Backened
      BASE_URL_IMPORT_EXPORT = "https://test.io.cloudhub360.com/api"
      BASE_URL_LOGS = "https://test.log.cloudhub360.com/api";
      AUTH0_BASE_URL = "https://test.um.cloudhub360.com"
      KNOWLEDGE_BASE_URL = "https://test.kb.cloudhub360.com";
      CHATBOT_BASE_URL = "https://test.botlist.cloudhub360.com/api";
      // CHATBOT_BASE_URL = "http://20.0.96.20:8080/api";
      BILLING_MANAGER_URL = 'https://test.billingmanager.cloudhub360.com';
      ASKELIE_TEST_REDIRECTION_URL = "https://test.digiworker.cloudhub360.com/";
    }
    // Demo Server 
    else if (server === "demo") {
      WORKBENCH_URL = 'https://demo.workbench.cloudhub360.com';                      //For UI Workbench
      BASE_APPLICATION = 'https://demo.server.cloudhub360.com'                            //For Workbench Backened
      BASE_URL_IMPORT_EXPORT = "https://demo.io.cloudhub360.com/api"
      BASE_URL_LOGS = "https://demo.logs.cloudhub360.com/api";
      AUTH0_BASE_URL = "https://demo.um.cloudhub360.com";
      KNOWLEDGE_BASE_URL = "https://demo.kb.cloudhub360.com";
      CHATBOT_BASE_URL = "https://botlist.cloudhub360.com/api"
      BILLING_MANAGER_URL = 'https://demo.billingmanager.cloudhub360.com';
      ASKELIE_TEST_REDIRECTION_URL = "https://askelie.cloudhub360.com/";
    }

    return {
      URL: {
        COLLECTIONS: {
          KEY: 'collections',
          GET: BASE_APPLICATION + '/api/collections',
          DELETE_URL: `${BASE_APPLICATION}/api/collections`,
        },
        FILTERS: {
          GET: BASE_APPLICATION + '/api/collections/$id/filters',
          KEY: 'filters'
        },
        DOCUMENT: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did',
          POST: BASE_APPLICATION + '/api/collections/$cid/documents',
          DELETE: BASE_APPLICATION + '/api/collections/$cid/documents/$did',
          KEY: 'document',
        },
        DOCUMENTS: {
          GET: BASE_APPLICATION + '/api/collections/$id/documents',
          KEY: 'documents'
        },
        ENTITIES: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/entities',
          PUT: BASE_APPLICATION + '/api/collections/$cid/documents/$did/entities',
          KEY: 'entities',
        },
        ENTITIES_TOKEN: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/values',
          KEY: 'entities_token',
        },
        MAPPINGS: {
          GET: BASE_APPLICATION + '/api/collections/$cid/documents/$did/mappings',
          POST: BASE_APPLICATION + '/api/collections/$cid/documents/$did/mappings',
          KEY: 'mappings'
        },
        GROUPS: {
          GET: BASE_APPLICATION + '/api/groups',
          KEY: 'groups'
        },
        RESOURCE_LOCK: {
          POST: BASE_APPLICATION + '/api/documents/$did/lock',
          GET: BASE_APPLICATION + '/api/documents/$did/lock',
          DELETE: BASE_APPLICATION + '/api/documents/$did/lock',
          KEY: 'lock'
        },
        IMPORT: {
          GET_LIST: BASE_URL_IMPORT_EXPORT,
          KEY: 'importconfig'
        },
        EXPORT: {
          GET_LIST: BASE_URL_IMPORT_EXPORT,
          KEY: 'exportconfig'
        },
        WORKBENCH: {
          GET_WORKBENCH_URL: WORKBENCH_URL,
          KEY: 'workbench'
        },
        LOGS: {
          GET_LOGS_URL: BASE_URL_LOGS,
          KEY: 'logs'
        },
        AUTH0: {
          BASE_URL: AUTH0_BASE_URL,
          KEY: 'auth0',
          USER_INFO: AUTH0_BASE_URL + '/api/token/userInfo'
        },
        WORKQUEUES: {
          BASE_URL: BASE_APPLICATION,
          KEY: 'workqueues'
        },
        CHATBOT: {
          BASE_URL: CHATBOT_BASE_URL,
          key: "chatbot"
        },
        MODEL: {
          BASE_URL: BASE_APPLICATION + '/api/models',
          key: "model"
        },
        KNOWLEDGE_BASE: {
          KEY: "knowledge-base",
          BASE_URL: KNOWLEDGE_BASE_URL + "/knowledge-base",
        },
        BILLING_MANAGER: {
          DASHBOARD_URL: BILLING_MANAGER_URL + '/admin-dashboard',
          KEY: 'workbench'
        },
        ASKELIE: {
          TEST_REDIRECTION_URL: `${ASKELIE_TEST_REDIRECTION_URL}login.html`
        }
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export {
  getRoutes,
  getUrl,
  getServerType
}
