const initialState = {
  customFiles: {},
  tableLoading: false,
}

const CustomFilesReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case "GET_CUSTOM_FILES_DATA":
    case 'ADD_CUSTOM_FILES_DATA':
    case 'UPDATE_CUSTOM_FILES_DATA':
      return {
        ...state,
        customFiles: payload,
      }

    case "SET_CUSTOM_FILES_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }

    default:
      return state
  }
}

export default CustomFilesReducer
