import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navbar } from "reactstrap";
import classnames from "classnames";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import credential from "./../../../configs/credential";
import { history } from "../../../history";
import { getLoginData } from "../../../redux/actions/auth/loginActions";

import defaultAvatar from "../../../assets/img/portrait/default-avatar.png"
import { getDecryptedItem, setEncryptedItem } from "../../../utility/encryptedStorage";

const defaultImg =
  "https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const [data, setData] = useState({});

  const organisationName = getDecryptedItem("orgName")

  useEffect(() => {
    props.getLoginData()
  }, [])

  useEffect(
    () => {
      setUserData();
    },
    [organisationName, data],
  );

  const getName = () => {
    // let email = getDecryptedItem("email");
    // let name = email?.split('@')[0];
    var username;
    const loginData = JSON.parse(getDecryptedItem("data"));
    const roles = loginData?.userInfo?.roles;

    if (roles?.length) {
      if (roles[0] === "SuperAdmin") {
        username = "Super Admin";
      } else if (roles[0] === "Partner") {
        username = loginData.userInfo.name;
      } else if (roles[0] === "Customer") {
        username = loginData.userInfo.name;
      } else if (roles.includes("Customer Admin") || roles.includes("Customer Designer") || roles.includes("Customer End User")) {
        username = loginData.userInfo.name;
      }
    } else {
      window.location.reload();
    }
    return username;
  };

  const getRoleName = () => {
    const loginData = JSON.parse(getDecryptedItem("data"));
    let roles = loginData?.userInfo?.roles?.slice() || [];
    if (!roles?.length) {
      window.location.reload();
    }
    roles = roles.filter(role => role !== "EndUser").join(", ")
    return roles;
  };

  const getProfileImg = () => {
    let profileImg = defaultImg;
    const loginData = JSON.parse(getDecryptedItem("data"));
    if (
      loginData !== "undefined" &&
      loginData !== null &&
      typeof loginData.userInfo !== "undefined"
    ) {
      profileImg = loginData.userInfo.userIconPath;
      const roles = loginData.userInfo.roles
      if (roles.includes("SuperAdmin")) {
        profileImg = defaultImg;
      } else if (roles.includes("Partner")) {
        profileImg = defaultImg;
      } else if (!profileImg) {
        profileImg = defaultImg;
      }
    } else {
      window.location.reload();
    }
    return profileImg;
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const setUserData = async () => {
    const data = credential;
    let email = getDecryptedItem("email");
    if (!email) {
      history.push("/login");
      return;
    }
    let res = data.filter((el) => el.email === email);
    setEncryptedItem("company", credential[0].company);
    if (res.length > 0) {
      setData(res[0]);
    } else {
      sleep(4500).then(() => {
        const orgName = getDecryptedItem("orgName");
        setData({
          name: getName(),
          email: getDecryptedItem("email"),
          status: getRoleName(),
          company: orgName,
          // image:'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png'
          image: getProfileImg(),
        });
      });
    }
  };

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  {/* <img src={mainLogo} className="main-logo" /> */}
                  <h2 className="text-primary brand-text mb-0">Cloudhub 361</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={`${data.name || ""}${data.status ? ` (${data.status})` : ""}`}
                userImg={data.image || defaultAvatar}
                company={data.company}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default connect(null, { getLoginData })(ThemeNavbar);
