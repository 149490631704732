const initialState = {
  data: [],
  params: null,
  allData: [],
  allPartner: [],
  totalPages: 0,
  filteredData: [],
  refresh: 0,
  modalData: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  customerNameRes: false,
  getPartnerById: {},
  getCustomerListById: [],
  getCustomerById: [],
  modalUsernameExist: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  modalDeleteRestricted: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  }
}

// const getIndex = (arr, arr2, arr3, params = { perPage: 0 }) => {
//   if (arr2.length > 0) {
//     let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
//     let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
//     let finalArr = [startIndex, endIndex]
//     return (arr3 = finalArr)
//   } else {
//     let finalArr = [arr.length - parseInt(params.perPage), arr.length]
//     return (arr3 = finalArr)
//   }
// }

const CustomerDataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODEL":
      return {
        ...state,
        modalData: action.value,
      }
    case "GET_ALL_CUSTOMER_DATA":
      return {
        ...state,
        allData: action.data,
      }
    case "FILTER_CUSTOMER_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.reference.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.reference.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
        // .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "ADD_CUSTOMER_DATA":
      return {
        ...state,
        allData: state.allData.concat(action.payload)
      }
    case "UPDATE_CUSTOMER_DATA":
      return {
        ...state,
        allData: state.allData.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    case "DELETE_CUSTOMER_DATA":
      return {
        ...state,
        allData: state.allData.filter((item) => item.id !== action.payload)
      }
    case "GET_PARTNER_DATA":
      return {
        ...state,
        allPartner: action.data,
      }
    case "CUSTOMER_NAME_CHECK":
      return {
        ...state,
        customerNameRes: action.data
      }
    case "GET_PARTNER_DATA_BASED_ON_ID":
      return {
        ...state,
        getPartnerById: action.data
      }
    case "GET_CUSTOMER_LIST_BY_ID":
      return {
        ...state,
        getCustomerListById: action.data,
      }
    case "OPEN_MODEL_USERNAME_EXIST":
      return {
        ...state,
        modalUsernameExist: action.value,
      }
    case "OPEN_MODEL_DELETE_RESTRICTED":
      return {
        ...state,
        modalDeleteRestricted: action.value,
      }
    case "GET_CUSTOMER_DATA_BY_ID":
      return {
        ...state,
        getCustomerById: action.data,
      }
    default:
      return state
  }
}

export default CustomerDataListReducer
