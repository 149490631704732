const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  refresh: 0,
  modalData: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
}

// const moveIndex = (arr, from, to) => {
//     let el = arr[from]
//     arr.splice(from, 1)
//     arr.splice(to, 0, el)
// }

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
    let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
    let finalArr = [startIndex, endIndex]
    return (arr3 = finalArr)
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length]
    return (arr3 = finalArr)
  }
}

const AdminstrationLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOG_DATA":
      return {
        ...state,
        data: action.data,
        // totalPages: action.totalPages,
        params: action.params,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        )
      }
    case "GET_ALL_LOG_DATA":
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        // sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    default:
      return state
  }
}

export default AdminstrationLogReducer
