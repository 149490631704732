/**
 * THE REDUCER for partner
 * @author Sandeep G
 * @sice 20201223
 */
// import { user } from "../../../configs/formJSON"

const initialState = {
  modalDeleteData: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  modalData: {
    emableModel: false,
    message: "",
    displayMsg: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  getUserByPartnerId: [],
  getUserByCustomerId: [],
  allData: [],
  total: '0',
  allRoles: []
};
const UserDataListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case "SET_USER_DATA":
      return {
        ...state,
        allData: action.data,
        total: action.data ? (action.data).length : 0,
      }
    case "UPDATE_ROLES_FORM":
      return {
        ...state,
        role_form_data: action.data
      }
    case "SELECTED_ROLE":
      return {
        ...state,
        selected_role:
        {
          ...state.selected_role,
          [action.id]: {
            idType: action.idType,
            id: action.id,
            status: action.status
          }
        }
      }
    case "SELECTED_ROLE_STATUS":
      return {
        ...state,
        selected_role_status:
        {
          ...state.selected_role_status,
          [action.id]: {
            status: action.status
          }
        }
      }
    case "CLEAR_SELECTED_ROLE_STATUS":
      return {
        ...state,
        selected_role_status: {}
      }
    case "OPEN_MODEL_DELETE":
      return {
        ...state,
        modalDeleteData: action.value,
      }
    case "ADD_USER":
      return {
        ...state,
        allData: state.allData.concat(payload),
      }
    case "UPDATE_USER": {
      const userId = payload.id
      return {
        ...state,
        allData: state.allData.map((user) => {
          return user.id === userId ? payload : user
        }),
      }
    }
    case "DELETE_USER": {
      const userId = payload
      return {
        ...state,
        allData: state.allData.filter((user) => user.id !== userId)
      }
    }
    case "OPEN_MODEL":
      return {
        ...state,
        modalData: action.value,
      }
    case "GET_USER_DATA_BY_PARTNER_ID":
      return {
        ...state,
        getUserByPartnerId: action.data
      }
    case "GET_USER_DATA_BY_CUSTOMER_ID":
      return {
        ...state,
        getUserByCustomerId: action.data
      }
    case "FILTER_USER_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.userName.toLowerCase().startsWith(value.toLowerCase()) ||
              item.createdBy.toLowerCase().startsWith(value.toLowerCase()) ||
              item.partnerName.toLowerCase().startsWith(value.toLowerCase()) ||
              item.customerName.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.userName.toLowerCase().includes(value.toLowerCase()) ||
              item.createdBy.toLowerCase().includes(value.toLowerCase()) ||
              item.partnerName.toLowerCase().includes(value.toLowerCase()) ||
              item.customerName.toLowerCase().includes(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
        // .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData, console: console.log(`filteredData In User Reducer`, filteredData) }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }

    case "SET_ALL_ROLES":
      return {
        ...state,
        allRoles: payload
      }

    case "UPDATE_USER_ICON_PATH_LOCALLY":
      const { id, url } = payload
      return {
        ...state,
        allData: state.allData.map((user) => {
          return user.id === id ? { ...user, userIconPath: url } : user
        }),
      }

    default:
      return state
  }
}

export default UserDataListReducer
