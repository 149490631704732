import { sortAscending } from "../../../utility/utility"

const initialState = {
  allSlotValidationsData: [],

  tableLoading: false,
  sidebarLoading: false,
  typeOptions: [
    { value: "CUSTOM", id: "CUSTOM" },
    { value: "GENERIC", id: "GENERIC" }
  ]
}

const ChatbotSlotValidationListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_SLOT_VALIDATIONS_DATA":
      return {
        ...state,
        allSlotValidationsData: sortAscending(action.data, "name")
      }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotSlotValidationListReducer
