import { sortAscending } from "../../../utility/utility"

const initialState = {
  allActionsData: [],
  allActionDataFilteredForDropdown: [],

  tableLoading: false,
  sidebarLoading: false
}

// const moveIndex = (arr, from, to) => {
//   let el = arr[from]
//   arr.splice(from, 1)
//   arr.splice(to, 0, el)
// }

// const getIndex = (arr, arr2, arr3, params = {}) => {
//   if (arr2.length > 0) {
//     let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
//     let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
//     let finalArr = [startIndex, endIndex]
//     return (arr3 = finalArr)
//   } else {
//     let finalArr = [arr.length - parseInt(params.perPage), arr.length]
//     return (arr3 = finalArr)
//   }
// }

// const trimUtterPrefixFromName = (nameString) => nameString && nameString.replace(/utter_/ig, "")

const ChatbotActionListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_ALL_ACTIONS_DATA":
      return {
        ...state,
        // allActionsData: action.data.map((item) => item.name ? { ...item, name: trimUtterPrefixFromName(item.name) } : item)
        allActionsData: sortAscending(action.data, "name"),
        allActionDataFilteredForDropdown: sortAscending(action.data.filter(({ onloadTriggerAction }) => !onloadTriggerAction), "name")
      }
    case "ADD_ACTION_DATA": {
      const newAllActionsData = sortAscending(state.allActionsData.concat(payload), "name");

      return {
        ...state,
        allActionsData: newAllActionsData,
        allActionDataFilteredForDropdown: newAllActionsData.filter(({ onloadTriggerAction }) => !onloadTriggerAction)
      }
    }
    case "UPDATE_ACTION_DATA": {
      const newAllActionsData = sortAscending(state.allActionsData.map((item) => item.id === payload.id ? { ...payload } : item), "name");

      return {
        ...state,
        allActionsData: newAllActionsData,
        allActionDataFilteredForDropdown: newAllActionsData.filter(({ onloadTriggerAction }) => !onloadTriggerAction)
      }
    }
    case "DELETE_ACTION_DATA": {
      const newAllActionsData = state.allActionsData.filter((item) => item.id !== payload)

      return {
        ...state,
        allActionsData: newAllActionsData,
        allActionDataFilteredForDropdown: newAllActionsData.filter(({ onloadTriggerAction }) => !onloadTriggerAction)
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotActionListReducer
