const initialState = {
  allModelsData: [],

  tableLoading: false,
  sidebarLoading: false
}

const ChatbotIntentListReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case "GET_ALL_MODELS_DATA":
      return {
        ...state,
        allModelsData: action.data
      }
    case "ADD_MODEL_DATA": {
      const { payload } = action

      return {
        ...state,
        allModelsData: state.allModelsData.concat(payload)
      }
    }
    case "UPDATE_MODEL_DATA": {
      const { payload } = action

      return {
        ...state,
        allModelsData: state.allModelsData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    }
    case "DELETE_MODEL_DATA": {
      const { payload } = action

      return {
        ...state,
        allModelsData: state.allModelsData.filter((item) => item.id !== payload)
      }
    }
    case "DELETE_MODEL_FIELD_DATA": {
      const { payload: { modelId, fieldId } } = action

      return {
        ...state,
        allModelsData: state.allModelsData.map((item) => {
          const { id } = item
          if (id === modelId) {
            return {
              ...item,
              fieldDetails: item.fieldDetails.filter((field) => field.id !== fieldId)
            }
          }
          return item
        })
      }
    }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }

    default:
      return state
  }
}

export default ChatbotIntentListReducer
