const initialState = {
  data: [],
  params: null,
  allData: [],
  allPartner: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  refresh: 0,
  connectorNameRes: {},
  trainChatbotRes: {},
  cloneChatbotRes: {},
  modalData: {
    emableModel: false,
    message: "",
    enableCancleButton: true,
    enableOkButton: true,
    headerMsg: ""
  },
  testGdriveRes: {},
  testEmailRes: {},
  addResponse: "",
  updateResponse: "",
  isActive: false,

  tableLoading: false,
  sidebarLoading: false,
  botDetailsLoading: false,
  botDetails: {
    // defaultWidgetStyle: {
    //   botColor: "",
    //   botIcon: "",
    //   botName: "",
    //   primaryBGC: "",
    //   primaryTextColor: "",
    //   secondaryBGC: "",
    //   secondaryTextColor: "",
    //   userIcon: ""
    // }
  }
}

// const moveIndex = (arr, from, to) => {
//   let el = arr[from]
//   arr.splice(from, 1)
//   arr.splice(to, 0, el)
// }

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
    let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
    let finalArr = [startIndex, endIndex]
    return (arr3 = finalArr)
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length]
    return (arr3 = finalArr)
  }
}

const ChatbotDataListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case "GET_CHATBOT_ALL_LIST":
      return {
        ...state,
        data: action.data,
        // totalPages: action.totalPages,
        params: action.params,
        sortIndex: getIndex(
          state.allData,
          action.data,
          state.sortIndex,
          action.params
        )
      }
    case "GET_ALL_CHATBOT_DATA":
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length,
        // sortIndex: getIndex(action.data, state.data, state.sortIndex)
      }
    case "OPEN_MODEL_DELETE":
      return {
        ...state,
        modalData: action.value,
      }
    case "FILTER_CHATBOT_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => {
            let startsWithCondition =
              item.name.toLowerCase().startsWith(value.toLowerCase()) ||
              item.connectorType.toLowerCase().startsWith(value.toLowerCase()) ||
              item.workspaceName.toLowerCase().startsWith(value.toLowerCase())

            let includesCondition =
              item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.connectorType.toLowerCase().includes(value.toLowerCase()) ||
              item.workspaceName.toLowerCase().startsWith(value.toLowerCase())

            if (startsWithCondition) {
              return startsWithCondition
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition
            } else return null
          })
          .slice(state.params.page - 1, state.params.perPage)
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "ADD_CHATBOT":
      // let id = state.data.slice(-1)[0].id + 1
      state.data.push({
        ...action.obj,
        // id,
      })
      // moveIndex(
      //     state.data,
      //     state.data.findIndex(item => item.id === id),
      //     0
      // )
      return {
        ...state,
        data: state.data,
        totalRecords: state.allData.length,
        addResponse: action.data,
        // sortIndex: getIndex(state.allData, state.data, state.sortIndex),
      }
    case "UPDATE_CHATBOT":
      state.data.find(item => {
        if (item.id === action.obj.id) {
          return Object.assign(item, {
            ...action.obj
          }
          )
        } else {
          return item
        }
      })
      return { ...state, updateResponse: action.data }
    case "DELETE_CHATBOT":
      let index = state.data.findIndex(item => item.id === action.obj.id)
      let updatedData = [...state.data]
      updatedData.splice(index, 1)
      return {
        ...state,
        data: updatedData,
        totalRecords: state.allData.length,
        sortIndex: getIndex(
          state.allData,
          state.data,
          state.sortIndex,
          state.params
        )
      }
    case "TRAIN_CHATBOT":
      return {
        ...state,
        trainChatbotRes: action.data
      }

    case "CLONE_CHATBOT":
      return {
        ...state,
        cloneChatbotRes: action.response.data
      }

    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: payload
      }
    case "SET_SIDEBAR_LOADING":
      return {
        ...state,
        sidebarLoading: payload
      }
    case "SET_BOT_DETAILS_LOADING":
      return {
        ...state,
        botDetailsLoading: payload
      }

    case "UPDATE_WIDGET_STYLE": {
      return state;
    }
    case "SET_BOT_DETAILS": {
      const botDetails = state.allData.find(({ id }) => id === payload)
      if (botDetails) {
        return {
          ...state,
          botDetails
        }
      }
      return {
        ...state,
        botDetails: {}
      }
    }

    case "UPDATE_AUTH_DETAILS":
      const { chatbotId, authType } = payload;
      const botDetails = state.allData.find(({ id }) => id === chatbotId);
      if (botDetails) {
        return {
          ...state,
          botDetails: {
            ...botDetails,
            authDetails: { type: authType }
          }
        };
      }
      return state;

    default:
      return state
  }
}

export default ChatbotDataListReducer
