import React from "react";
import askelie from "./../assets/icons/askelie.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCalendar,
  faTachometerAlt,
  faCrosshairs,
  faCog,
  faUnlockAlt,
  faHandshake,
  faUser,
  faUsers,
  faWrench,
  faCommentDots
} from "@fortawesome/free-solid-svg-icons";
import { history } from "./../history";

import { getUrl } from "../utility/context/Config";
import { getDecryptedItem } from "../utility/encryptedStorage";

var userId = "";
userId = getDecryptedItem("id");
const token = getDecryptedItem("token");

const CONFIG = getUrl()
const WORKBENCH_URL = CONFIG.URL.WORKBENCH.GET_WORKBENCH_URL
const BILLING_MANAGER_URL = CONFIG.URL.BILLING_MANAGER.DASHBOARD_URL

const loginData = JSON.parse(getDecryptedItem("data"));
if (loginData !== null) {
  userId = loginData?.userInfo?.id || "";
} else {
  history.push('/login');
}
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <FontAwesomeIcon icon={faHome} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "Group1",
    groupTitle: "App Links",
    type: "groupHeader",
  },
  {
    id: "Workbench",
    title: "Workbench",
    type: "external-link",
    icon: <FontAwesomeIcon icon={faCalendar} className="menu-icon" />,
    permissions: ["admin", "editor"],
    newTab: true,
    navLink: WORKBENCH_URL + "?origin=cloudhub360&token=" + token,
  },
  {
    id: "askelie",
    title: "askelie",
    type: "item",
    icon: <img src={askelie} className="elie-icon" alt="elie-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/askelie",
  },
  {
    id: "billing",
    title: "Billing Manager",
    type: "external-link",
    icon: <FontAwesomeIcon icon={faTachometerAlt} className="menu-icon" />,
    permissions: ["admin", "editor"],
    newTab: true,
    navLink: BILLING_MANAGER_URL + "/?token=" + token,
  },
  {
    id: "insight",
    title: "Insight",
    type: "item",
    icon: <FontAwesomeIcon icon={faCrosshairs} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/insight",
  },
  {
    id: "api-service",
    title: "API Services",
    type: "item",
    icon: <FontAwesomeIcon icon={faHandshake} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/api",
  },
  //   children: [
  //     {
  //       id: "contract",
  //       title: "Contract Management",
  //       type: "item",
  //       icon: '',
  //       permissions: ["admin", "editor"],
  //       navLink: "/contract"
  //       // navLink: "http://ec2-18-135-29-159.eu-west-2.compute.amazonaws.com:5002/insight"
  //     },
  //     {
  //       id: "ins-claim",
  //       title: "Insurance Claims",
  //       type: "item",
  //       icon: '',
  //       permissions: ["admin", "editor"],
  //       navLink: "/insurance"
  //     }]
  // },
  {
    id: "Group2",
    groupTitle: "Setup",
    type: "groupHeader",
  },
  {
    id: "config",
    title: "Composer",
    type: "item",
    icon: <FontAwesomeIcon icon={faCog} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/composer",
  },
  {
    id: "admin-menu",
    title: "Administration",
    type: "item",
    icon: <FontAwesomeIcon icon={faUnlockAlt} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/adminstration",
  },
  {
    id: "user",
    title: "User Setup",
    type: "item",
    icon: <FontAwesomeIcon icon={faUser} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/user-setup",
  },
  {
    id: "partnet-setup",
    title: "Partner Setup",
    type: "item",
    icon: <FontAwesomeIcon icon={faUsers} className="menu-icon" />,
    permissions: ["admin"],
    navLink: "/partner-setup",
  },
  {
    id: "customer-setup",
    title: "Customer Setup",
    type: "item",
    icon: <FontAwesomeIcon icon={faUsers} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/customer-setup",
  },
  {
    id: "askelie-bot-composer",
    title: "Askelie Bot Servers",
    type: "item",
    icon: <FontAwesomeIcon icon={faCommentDots} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/bot-servers",
  },
  {
    id: "Group3",
    groupTitle: "",
    type: "groupHeader",
  },
  {
    id: "prefer",
    title: "Preferences",
    type: "item",
    icon: <FontAwesomeIcon icon={faWrench} className="menu-icon" />,
    permissions: ["admin", "editor"],
    navLink: "/preferences",
  }
];

const configSetupSubModules = ['Export Connectors', 'Import Connectors', 'Process Flow Setup', 'askelie AI Setup', 'Extraction Setup', 'Insight Setup', 'Model Setup', 'KB Setup']

const group1Ids = ["Workbench", "askelie", "billing", "insight", "api-service"];
const group2Ids = ["config", "admin-menu", "user", "partnet-setup", "customer-setup", "askelie-bot-composer"];
const group3Ids = ["prefer"];

export {
  navigationConfig, configSetupSubModules,
  group1Ids, group2Ids, group3Ids
};
