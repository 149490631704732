import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import customerDataListReducer from "./customer/"
import PartnerDataListReducer from "./partner/"
import UserDataListReducer from "./user/"
import ExportConfigDataListReducer from "./exportConfig"
import ImportConfigDataListReducer from "./importConfig"
import AdminstrationLogReducer from "./adminstrationLog"
import ChatbotDataListReducer from "./askeleieSetup"
import ChatbotActionListReducer from "./chatbotActions"
import ChatbotEntityListReducer from "./chatbotEntity"
import ChatbotIntentListReducer from "./chatbotIntent"
import ChatbotStoryListReducer from "./chatbotStory"
import ChatbotSlotListReducer from "./chatbotSlot"
import ChatbotSlotValidationListReducer from "./chatbotSlotValidation"
import ChatbotFormListReducer from "./chatbotForm"
import WaivesReducer from "./waives"
import KnowledgeBaseReducer from "./knowledgeBase"
import ModelConfigReducer from "./modelConfig"
import BotServers from "./botServers"
import CustomFilesReducer from "./askeleieSetup/CustomFiles"
import ChatbotVersionReducer from "./chatbotVersion"

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  customerDataListReducer: customerDataListReducer,
  exportConfigDataListReducer: ExportConfigDataListReducer,
  importConfigDataListReducer: ImportConfigDataListReducer,
  partnerDataListReducer: PartnerDataListReducer,
  userDataListReducer: UserDataListReducer,
  adminstrationLogReducer: AdminstrationLogReducer,
  chatbotDataListReducer: ChatbotDataListReducer,
  chatbotActionListReducer: ChatbotActionListReducer,
  chatbotEntityListReducer: ChatbotEntityListReducer,
  chatbotIntentListReducer: ChatbotIntentListReducer,
  chatbotStoryListReducer: ChatbotStoryListReducer,
  chatbotSlotListReducer: ChatbotSlotListReducer,
  chatbotSlotValidationListReducer: ChatbotSlotValidationListReducer,
  chatbotFormListReducer: ChatbotFormListReducer,
  waivesReducer: WaivesReducer,
  knowledgeBaseReducer: KnowledgeBaseReducer,
  modelConfigReducer: ModelConfigReducer,
  botServersReducer: BotServers,
  customFilesReducer: CustomFilesReducer,
  chatbotVersionReducer: ChatbotVersionReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
