/**
 * All the utility functions goes here
 * @author Sandeep G
 * @since 20201206
 */
import React from 'react';
import ReactDOM from 'react-dom'

import ComponentSpinner from './../components/@vuexy/spinner/Loading-spinner'

/**
  * resonsible for showing the loader
 */
function showLoader(statusBit) {
  ReactDOM.render(<ComponentSpinner show={statusBit} />,
    document.getElementById('custom-loader'));
}

/**
 * responsible for checking the validity of the mail
 * @param {} email 
 */
const isValidEmail = (email) => {
  if (!email)
    return false;

  //match the regex
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!pattern.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
}

/**
 * responsible for checking the validity of the dates
 * 1. dates are valid 
 * 2. start date is smaller than end date
 */
const validateStartEndDate = (start, end) => {
  //validate the date type
  if ((new Date(start) > new Date(end)) || (new Date(end) < new Date(start))) {
    return false;
  }

  return true;
}
/**
 * responsible for checking the validity of contact number
 */
const validateContactNumber = (contactNumber) => {
  if ((contactNumber).length !== 11) {
    return false;
  }
  return true;
}

const removeDuplicatesFromAnArray = (inputArray) => inputArray.filter((element, index) => inputArray.indexOf(element) === index)

const getMaskedEmail = (email) => {
  if (email) {
    let split = email.split('@')
    return email.substr(0, 1) + "..." + split[0].substr(split[0].length - 1) + "@" + split[1]
  }
  return email
}

const removeAllSpaces = (str) => str ? str.replace(/\s/g, '') : str

const sortAscending = (arr, key) => {
  if (arr?.length) {
    if (key) {
      return arr.slice().sort((a, b) => {
        if (a[key] < b[key]) { return -1; }
        if (a[key] > b[key]) { return 1; }
        return 0;
      })
    }
    return arr.slice().sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    })
  }
  return arr
}

const convertTimestampToBrowserDate = (timestampInMilliseconds, format) => {
  if (timestampInMilliseconds) {
    const date = new Date(timestampInMilliseconds)

    let day = date.getDate()
    let month = date.getMonth() + 1
    const year = date.getFullYear()

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    switch (String(format).toLowerCase()) {
      case "dd-mm-yyyy":
        return `${day}-${month}-${year}`
      case "mm-dd-yyyy":
        return `${month}-${day}-${year}`
      case "yyyy-mm-dd":
        return `${year}-${month}-${day}`
      default:
        return `${day}-${month}-${year}`
    }
  }

  return "";
}

const convertBrowserDateToTimestamp = (dateString = "") => {
  // expected date format yyyy-MM-dd
  if (dateString) {
    // returning timestamp in milliseconds
    return (new Date(dateString)).getTime()
  }
  return dateString;
}

export {
  isValidEmail,
  validateStartEndDate,
  validateContactNumber,
  showLoader,
  removeDuplicatesFromAnArray,
  getMaskedEmail,
  removeAllSpaces,
  sortAscending,
  convertTimestampToBrowserDate, convertBrowserDateToTimestamp
}
